import { createRouter, createWebHistory } from 'vue-router';
import JobDetails from '../components/JobDetails.vue'; // Komponente für die Detailansicht
import JobsAll from '../components/JobsAll.vue'; 

const routes = [
  { path: '/', component: JobsAll }, // Stelle sicher, dass dies vorhanden ist
  { path: '/jobs/:id', component: JobDetails },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
