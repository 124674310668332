<template>
  <div>
    <div v-if="isLoading" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="main-content" :class="{ 'is-hidden': isLoading }">
      <div class="section padding">
        <div class="container spacing_zero less-padding w-container">
          <div class="content-header-spread">
            <div class="limit">
              <h2>Latest job posts</h2>
              <div class="large-text"></div>
            </div>
          </div>
          <div class="filters">
            <select v-model="selectedLocation" class="filter-dropdown">
              <option value="">All Locations</option>
              <!-- Dynamically generate options from jobs -->
              <option v-for="loc in uniqueLocations" :key="loc" :value="loc">
                {{ loc }}
              </option>
            </select>

            <select v-model="selectedCategory" class="filter-dropdown">
              <option value="">All Categories</option>
              <!-- Dynamically generate options from jobs -->
              <option v-for="cat in uniqueCategories" :key="cat" :value="cat">
                {{ cat }}
              </option>
            </select>

            <select v-model="selectedLevel" class="filter-dropdown">
              <option value="">All Levels</option>
              <!-- Dynamically generate options from jobs -->
              <option v-for="level in uniqueLevels" :key="level" :value="level">
                {{ level }}
              </option>
            </select>
          </div>
          <div class="horizontal-jobs-wrapper">
            <div class="w-dyn-list">
              <div role="list" class="horizontal-jobs-grid w-dyn-items">
                <div
                  v-for="job in combinedFilteredJobs"
                  :key="job.id"
                  class="job-card-item w-dyn-item job-card horizontal"
                  :data-job-id="job.id"
                >
                  <router-link
                    :to="'/jobs/' + job.id"
                    class="job-card-company-wrap square w-inline-block"
                  >
                    <div class="company-large-icon-wrap">
                      <img
                        :src="job.properties.companyImageLink.url"
                        alt="Company Logo"
                        class="company-icon-job"
                      />
                    </div>
                  </router-link>
                  <div class="job-card-content horizontal">
                    <div>
                      <a
                        :href="job.jobLink"
                        class="job-card-link w-inline-block"
                      >
                        <h3 class="heading-11">
                          {{ job.properties.job_title.title[0].text.content }}
                        </h3>
                      </a>
                      <div class="text-block-26">{{ job.description }}</div>
                    </div>
                    <div class="job-tags-wrap">
                      <a :href="job.employmentTypeLink" class="job-tag">{{
                        job.properties.employment_type.select.name
                      }}</a>
                      <a :href="job.levelLink" class="job-tag">{{
                        job.properties.level.select.name
                      }}</a>
                      <a :href="job.locationLink" class="job-tag">{{
                        job.properties.location.rich_text[0].text.content
                      }}</a>
                    </div>
                    <div class="job-card-head">
                      <a :href="job.categoryLink" class="link">{{
                        job.properties.category.select.name
                      }}</a>
                      <div class="opacity-black">
                        {{
                          new Date(job.properties.created_time.created_time)
                            .toISOString()
                            .slice(0, 10)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import axios from "axios";

export default {
  name: "JobList",
  setup() {
    const jobs = ref([]);
    const observer = ref(null);
    const selectedLocation = ref("");
    const selectedCategory = ref("");
    const selectedLevel = ref("");

    const isLoading = ref(false);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Tausche Elemente
      }
      return array;
    };

    const fetchJobs = async () => {
      isLoading.value = true; // Start des Ladevorgangs

      try {
        const response = await axios.get(
          "https://adan-secureserver.mainly-dev.de:3000/jobs"
        );
        // Anwenden der Shuffle-Funktion auf die abgerufenen Daten
        jobs.value = shuffleArray([...response.data]);

        // Sie könnten hier eine kleine Verzögerung einbauen, um den Ladebalken sichtbar zu machen,
        // besonders wenn der Shuffle-Vorgang sehr schnell ist.
      } catch (error) {
        console.error("Fehler beim Abrufen der Jobdaten: ", error);
      } finally {
        isLoading.value = false; // Ende des Ladevorgangs
      }
    };

    onMounted(fetchJobs); // Aufruf von fetchJobs, wenn die Komponente eingehängt wird

    const combinedFilteredJobs = computed(() => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Setzt die Uhrzeit auf 00:00:00, um nur das Datum zu vergleichen

      // Überprüft, ob onlineUntil nicht in der Vergangenheit liegt (ALL)

      return jobs.value.filter((job) => {
        const onlineUntil = new Date(job.properties.onlineUntil.date.start);
        const isFutureDate = onlineUntil >= currentDate;
        const matchesLocation =
          !selectedLocation.value ||
          job.properties.location.rich_text[0].text.content ===
            selectedLocation.value;
        const matchesCategory =
          !selectedCategory.value ||
          job.properties.category.select.name === selectedCategory.value;
        const matchesLevel =
          !selectedLevel.value ||
          job.properties.level.select.name === selectedLevel.value;
        const isPartnerPortal = job.properties["gebeya.com"].checkbox;
        return (
          isFutureDate &&
          matchesLocation &&
          matchesCategory &&
          matchesLevel &&
          isPartnerPortal
          //isFutureDate && matchesLocation && matchesCategory && matchesLevel
        );
      });
    });
    const trackJobViewport = async (jobId) => {
      try {
        await axios.post(
          `https://adan-secureserver.mainly-dev.de:3000/jobs/${jobId}/viewport`
        );
      } catch (error) {
        console.error("Fehler beim Tracken des Job-Viewports:", error);
      }
    };

    const observeJobs = () => {
      const options = {
        root: null, // bezieht sich auf den Viewport
        rootMargin: "0px",
        threshold: 0.1, // 10% des Elements sind im Viewport
      };

      observer.value = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Element ist im Viewport, führe Tracking aus
            trackJobViewport(entry.target.dataset.jobId);
            observer.unobserve(entry.target); // Stoppe die Beobachtung, um mehrfaches Zählen zu vermeiden
          }
        });
      }, options);

      // Füge jedes Job-Element zum Observer hinzu
      document.querySelectorAll(".job-card-item").forEach((el) => {
        observer.value.observe(el);
      });
    };

    onMounted(async () => {
      await fetchJobs();
      // Warte einen kurzen Moment, damit die DOM-Elemente geladen werden können
      setTimeout(observeJobs, 1000);
    });

    onUnmounted(() => {
      // Bereinige den Observer, wenn die Komponente zerstört wird
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    // Eindeutige Werte für Dropdowns extrahieren
    const uniqueLocations = computed(() => {
      const locations = jobs.value.map(
        (job) => job.properties.location.rich_text[0].text.content
      );
      return [...new Set(locations)];
    });

    const uniqueCategories = computed(() => {
      const categories = jobs.value.map(
        (job) => job.properties.category.select.name
      );
      return [...new Set(categories)];
    });

    const uniqueLevels = computed(() => {
      const levels = jobs.value.map((job) => job.properties.level.select.name);
      return [...new Set(levels)];
    });

    return {
      isLoading,
      combinedFilteredJobs,
      selectedLocation,
      selectedCategory,
      selectedLevel,
      uniqueLocations,
      uniqueCategories,
      uniqueLevels,
    };
  },
};

// IM LIVEBETRIEB LOCALHOST WIEDER AUF
// https: // 172. 104. 134. 243 oder
// https:// adan- secureserver . mainly-dev.de
// ÄNDERN
</script>

<style scoped>
.filters {
  display: flex;
  flex-wrap: wrap; /* Erlaubt das Umbruch der Elemente */
  gap: 10px; /* Abstand zwischen den Filter-Elementen */
  justify-content: center; /* Zentriert die Filter, wenn weniger Platz vorhanden ist */
}

.filter-dropdown {
  padding: 5px; /* Fügt Padding innerhalb des Dropdowns für bessere Lesbarkeit hinzu */
  min-width: 120px; /* Mindestbreite für jedes Dropdown, um sicherzustellen, dass Inhalte sichtbar sind */
  flex: 1 1 auto; /* Ermöglicht den Dropdowns, sich innerhalb des verfügbaren Raums auszudehnen */
}

@media (max-width: 600px) {
  /* Stiländerungen für Bildschirme kleiner als 600px */
  .filters {
    flex-direction: column; /* Stapelt die Filter-Elemente untereinander */
  }

  .filter-dropdown {
    width: 100%; /* Lässt die Dropdowns die volle Breite einnehmen */
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* oder 'absolute', abhängig vom Kontext */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* leicht transparenter Hintergrund */
  z-index: 10; /* Stellt sicher, dass der Spinner über anderen Inhalten liegt */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #a4d7d4;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-hidden {
  display: none;
}
</style>
