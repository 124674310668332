<!-- App.vue -->
<template>
  <div id="app">
    <router-view></router-view> <!-- Verwendet als Platzhalter für Routen-Komponenten -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import "./components/styles/normalize.css";
@import "./components/styles/webflow.css";
@import "./components/styles/adan-careers.webflow.css";
</style>
